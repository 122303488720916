import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import Layout from "../components/layout";
import theme from "../styles/theme";

const ArticlePage = ({ data }) => {
  return (
    <Layout
      location="/news"
      seo={data.article.seoMetaTags}
      logo={data.global.logo}
    >
      <main
        css={`
          margin: 0 auto;
          padding-bottom: 100px;
          width: 100%;
          max-width: 1600px;
          box-sizing: border-box;
          padding-top: 100px;
          ${theme.laptop} {
            padding-top: 50px;
          }
          ${theme.tablet} {
            padding-bottom: 0;
          }
          h1 {
            font-size: 72px;
            line-height: 110%;
          }
          .excerpt {
            font-size: 28px;
            font-weight: 900;
            line-height: 130%;
            color: ${theme.pink};
          }
          .imgHero {
            width: 100%;
          }
          .contentWrapper {
            max-width: 900px;
          }
          .content {
            iframe {
              width: 100%;
            }
            p {
              line-height: 1.2;
              line-height: 1.6;
              margin-top: 30px;
            }
            h2 {
              color: ${theme.pink};
              font-size: 28px;
              margin-top: 40px;
              margin-bottom: 0;
            }
            h3 {
              margin-top: 20px;
              font-size: 24px;
              margin-bottom: 0;
              font-weight: 900;
            }
            blockquote {
              font-weight: 900;
              margin-left: 0;
            }
            img {
              max-width: 100%;
            }
            a {
              color: ${theme.pink};
              opacity: 0.7;
              font-weight: 700;
              &:hover {
                opacity: 1;
                transition: opacity 0.2s;
              }
            }
          }
          ${theme.mobile} {
            h1 {
              font-size: 32px;
            }
            .excerpt {
              font-size: 18px;
            }
            p {
              font-size: 16px;
            }
          }
        `}
      >
        <section
          css={`
            padding-top: 100px;
            padding-bottom: 100px;
            flex-direction: column;
            ${theme.mobile} {
              padding-top: 40px;
              padding-bottom: 40px;
            }
          `}
        >
          <Img
            className="imgHero"
            fluid={data.article.img.fluid}
            alt={data.article.img.alt}
          />
          <div className="contentWrapper">
            <h1>{data.article.title}</h1>
            <p className="excerpt">{data.article.excerpt}</p>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: data.article.content }}
            />
          </div>
        </section>
        <section
          css={`
            flex-direction: column;
            margin-top: 80px;
            margin-bottom: 0;
            h2 {
              margin: 0 0 40px;
            }
            a.allNews {
              max-width: 220px;
              margin: 80px auto;
              ${theme.mobile} {
                margin-top: 20px;
              }
            }
          `}
        >
          <h2>Latest news</h2>
          <div
            css={`
              display: grid;
              justify-content: center;
              grid-gap: 20px;
              grid-template-columns: repeat(3, 1fr);
              ${theme.max600} {
                grid-template-columns: repeat(1, 1fr);
                > div:not(:last-child) {
                  border-right: none !important;
                }
                > div {
                  margin-top: 20px;
                  margin-bottom: 30px;
                }
              }
              > div:not(:last-child) {
                border-right: 1px solid ${theme.pink};
              }

              > div {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 100%;
                padding-right: 30px;
                box-sizing: border-box;
                h3 {
                  color: ${theme.grey};
                  margin: 0;
                  font-weight: 900;
                  font-size: 24px;
                  line-height: 110.4%;
                  margin-bottom: 20px;
                }
                p {
                  font-size: 18px;
                  line-height: 118%;
                  margin: 0 0 20px;
                  //flex-grow: 1;
                }
                .date {
                  margin: 0 0 20px;
                }
                .button {
                  background: ${theme.pink};
                  color: #fff;
                }
              }
            `}
          >
            {data.news.edges.map(item => {
              return (
                <div>
                  <h3>{item.node.title}</h3>
                  <span className="date">{item.node.published}</span>
                  <p>{item.node.excerpt}</p>
                  <div>
                    <Link className="button" href={`/news/${item.node.slug}`}>
                      Read more
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          <Link to="/news" className="button allNews">
            All news
          </Link>
        </section>
      </main>
    </Layout>
  );
};

export default ArticlePage;

export const query = graphql`
  query ArticleQuery($slug: String!) {
    article: datoCmsArticle(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      excerpt
      img {
        fluid(maxWidth: 1600) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      content
    }
    news: allDatoCmsArticle(
      limit: 3
      sort: { fields: [published], order: DESC }
    ) {
      edges {
        node {
          title
          excerpt
          slug
          published(formatString: "DD.MM.YYYY", locale: "en")
        }
      }
    }
    global: datoCmsGlobal {
      logo {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
    }
  }
`;
